import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import hawkeye from "../images/hawkeye-logo.png"

const ArtificialInt = () => (
  <Layout>
    <SEO title="Artificial Intelligence" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-blue-main">Artificial Intelligence</h1>
        <div className="row">
          <div className="col-lg-8">
            <p>
              Tired of processing and sifting through all the data that your
              business record accumulate? Want to make the best out of these
              huge sets of information?
            </p>
            <p>
              Through Pacific Coast’s data extraction, machine learning, and
              artificial intelligence services - you can turn physical records
              into digital data and distributed datasets into cohesive reports.
            </p>
          </div>

          {/* <div className="col-lg-12 mx-auto text-center my-4">
            <iframe
              title="Artificial Intelligence"
              width="100%"
              src="https://www.youtube.com/embed/vOvwJE8Xuwk"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                border: "2px solid #248dcb",
                borderRadius: "5px",
                height: "500px",
              }}
            ></iframe>
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <img src={hawkeye} className="img-fluid" />
          </div>
          <div className="col-lg-6 my-lg-5 my-md-5 my-5">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/oHzXzk96wHw"
              title="Hawkeye Powered by Grooper"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                border: "2px solid #248dcb",
                borderRadius: "5px",
                height: "350px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">Advanced Optical Character Recognition</h2>
          </div>
          <div className="col-lg-8">
            <p>
              Have a thorough understanding of your business documents and data
              with the help of OCR (Optical Character Recognition) technology.
            </p>
            <p>
              Since humans have to manually read and understand characters and
              words page by page, the process naturally bogs down. There are
              only so many working hours in a day, after all. In an environment
              where quick processing of information is required to maximize
              productivity and profitability, the delays can be truly a
              headache.
            </p>
            <p>
              With advanced OCR technology, your whole team can shed the burden
              of manual data extraction, sorting, and processing. This process
              involves high-level AI and computing power to mechanically and
              automatically organize all sorts of data.
            </p>
            <p>
              Whether the information are from files, spreadsheets, information
              systems, or repositories - intelligent document processing can be
              achieved.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">
              Automate Data Extraction
              <br />
              Using Artificial Intelligence
            </h2>
          </div>
          <div className="col-lg-10 mx-lg-auto my-3">
            <ul style={{ columns: "2" }}>
              <li>Smart page identification and document classification</li>
              <li>Smooth file and information sorting</li>
              <li>Better organization and curation</li>
              <li>Quick scanning of images and formatting</li>
              <li>Easy creation of data models</li>
              <li>Convenient data streams to workflows</li>
              <li>Software application integrations</li>
              <li>Content tagging and named entities</li>
              <li>And so much more</li>
            </ul>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  As our valued customers, you can easily extract lease data,
                  lessor and lessee information, dates, terms, acreage and legal
                  descriptions, contract details, special provisions, and other
                  important recorded information.
                </p>
                <p>
                  Go through surface contracts and easements and right of way
                  agreements to find the needed data to make intelligent
                  business decisions. You can even extract calls to GIS Maps and
                  process AFE and JIB Extractions.{" "}
                </p>
                <p>
                  Get the details like never before and make better decisions.
                  Push your business’ data extraction and sorting capabilities
                  to new limits through artificial intelligence. Piles of data
                  sheets and files, whether physical or digital, won’t be a
                  headache anymore.
                </p>
                <p>
                  To know more about our data extraction and artificial
                  intelligence solutions, send us an email at
                  <a
                    href="mailto:info@pacificcoastls.com"
                    className="mx-1 text-blue-main"
                  >
                    info@pacificcoastls.com
                  </a>
                  or reach us via this number:
                  <a href="tel:720.234.1155" className="mx-1 text-blue-main">
                    720.234.1155
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ArtificialInt
